import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import apolloClient from 'apolloClient';
import * as Core from 'components/core';
import Home from './pages/Home';
import PreCheckout from './PreCheckout';

const BookBuilder = React.lazy(() => import('components/book-builder'));

const Poster = React.lazy(() => import('components/poster'));

const GiftCertificate = React.lazy(() => import('components/gift-certificate'));

const OrderComplete = React.lazy(
  () => import('components/shared/OrderComplete')
);

const AboutUs = React.lazy(() => import('components/pages/AboutUs'));

const TermsOfService = React.lazy(
  () => import('components/pages/TermsOfService')
);

const FrequentlyAskedQuestions = React.lazy(
  () => import('components/pages/FrequentlyAskedQuestions')
);

const Subscribed = React.lazy(() => import('components/Subscribed'));

interface Props {}

const Application: React.FC<Props> = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <React.Suspense fallback={<Core.Loader />}>
          <Core.ScrollToTop />
          <Core.TrackPageView />

          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>

            <Route path="/about-us" exact>
              <AboutUs />
            </Route>

            <Route path="/terms-of-service" exact>
              <TermsOfService />
            </Route>

            <Route path="/faq" exact>
              <FrequentlyAskedQuestions />
            </Route>

            <Route path="/pre-checkout" exact>
              <PreCheckout />
            </Route>

            <Redirect from="/checkout" to="/checkout/book" exact />

            <Route path="/checkout/book">
              <BookBuilder />
            </Route>

            <Route path="/checkout/poster">
              <Poster />
            </Route>

            <Route path="/gift">
              <GiftCertificate />
            </Route>

            <Route path="/subscribed" exact>
              <Subscribed />
            </Route>
          </Switch>
        </React.Suspense>
      </Router>
    </ApolloProvider>
  );
};

export default Application;
