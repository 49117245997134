import { upperFirst } from 'lodash';
import dotProp from 'dot-prop-immutable';
import { Operation } from 'utils/operations';
import { AuthorPronoun } from 'utils/pronoun';

import { BookState } from '../index';

type Action = {
  type: 'BOOK/SET_BOOK_DATA_VALUE';
  payload: {
    keyPath: string;
    value: unknown;
  };
};

class SetBookDataValue extends Operation {
  static actionType = 'BOOK/SET_BOOK_DATA_VALUE';

  actionCreator(keyPath: string, value: unknown): Action {
    return {
      type: 'BOOK/SET_BOOK_DATA_VALUE',
      payload: {
        keyPath,
        value
      }
    };
  }

  reducer(state: BookState, action: Action): BookState {
    const { keyPath, value } = action.payload;

    // Update the value in book data
    state = dotProp.set(state, `bookData.${keyPath}`, value);
    state = dotProp.set(state, 'informationVerified', false);

    // Handle special cases
    if (
      state.personalNoteEdited === false &&
      (keyPath === 'shortName' || keyPath === 'authorPronoun')
    ) {
      state = dotProp.set(
        state,
        'bookData.personalNote',
        defaultPersonalNote(
          state.bookData.shortName,
          state.bookData.authorPronoun
        )
      );
    } else if (keyPath === 'personalNote') {
      state = dotProp.set(state, 'personalNoteEdited', true);
    }

    return state;
  }
}

function defaultPersonalNote(
  name: string,
  authorPronounString: 'singular' | 'plural' | null
): string {
  const pronoun = new AuthorPronoun(authorPronounString);

  return `Welcome to the wonderful world! Your arrival has brought so much joy to our whole family. ${upperFirst(
    pronoun.nominative()
  )} can’t wait to watch you grow up, and for all the wonderful times ahead. The Little Book of ${name} may be your first story, but there will be many more. ${upperFirst(
    pronoun.nominative()
  )} hope that reading it will bring you magical dreams for years to come. ${upperFirst(
    pronoun.nominative()
  )} love you to the moon and back.`;
}

export default new SetBookDataValue();
