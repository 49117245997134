import './styles.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { range } from 'lodash';

import Book from 'modules/book';
import { State, Dispatch } from 'bundles/application/store';
import { getDateComponents, dateMergingComponents } from 'utils/dates';

import BookBuilderField from '../field';
import { BookBuilderSelect } from '../select';

interface Props {
  keyPath: string;
}

interface ProvidedProps extends Props {
  value: Date;
  onChange(date: Date): void;
}

/**
 * Renders three dropdowns to select a date: hour, minute, and period (am/pm) Structured
 * specifically for the book builder.
 *
 * The default export is connected ot the store, but the unwrapped class can be imported directly
 * to handle events another way.
 */
export class BookBuilderTimeSelect extends Component<ProvidedProps> {
  handleChange(component: 'minute' | 'hour' | 'period', value: unknown) {
    this.props.onChange(
      dateMergingComponents(this.props.value, {
        [component]: value
      })
    );
  }

  render() {
    const dateComponents = getDateComponents(this.props.value);
    return (
      <div className="BookBuilderCoreTimeSelect">
        <BookBuilderField label="Hour" small={true}>
          <BookBuilderSelect
            value={String(dateComponents.hour)}
            numeric
            testId="birthHour"
            onChange={this.handleChange.bind(this, 'hour')}
          >
            {range(1, 13).map(hour => {
              return (
                <option value={hour} key={hour}>
                  {hour}
                </option>
              );
            })}
          </BookBuilderSelect>
        </BookBuilderField>
        <span>:</span>
        <BookBuilderField label="Minute" small={true}>
          <BookBuilderSelect
            value={String(dateComponents.minute)}
            numeric
            testId="birthMinute"
            onChange={this.handleChange.bind(this, 'minute')}
          >
            {range(0, 60).map(minute => {
              return (
                <option value={minute} key={minute}>
                  {`0${minute}`.slice(-2)}
                </option>
              );
            })}
          </BookBuilderSelect>
        </BookBuilderField>
        <BookBuilderField label="" small={true}>
          <BookBuilderSelect
            value={String(dateComponents.period)}
            numeric
            testId="birthPeriod"
            onChange={this.handleChange.bind(this, 'period')}
          >
            <option value="0">AM</option>;<option value="1">PM</option>;
          </BookBuilderSelect>
        </BookBuilderField>
      </div>
    );
  }
}

function mapStateToProps(state: State, props: Props) {
  return {
    value: Book.getBookDataValue(state, props.keyPath)
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props) {
  return {
    onChange(value: Date) {
      if (props.keyPath) {
        dispatch(Book.setBookDataValueAction(props.keyPath, value));
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookBuilderTimeSelect);
