import './styles.scss';

import React from 'react';

type Props = {
  children?: React.ReactNode;
};

export default function BookBuilderParagraph({ children }: Props) {
  return <div className="BookBuilderParagraph">{children}</div>;
}
