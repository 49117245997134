import './Header.scss';

import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';

import logoUrl from 'assets/images/logo.svg';

interface Props {
  minimal?: boolean;
}

const Header: React.FC<Props> = props => {
  const { minimal } = props;

  return (
    <header>
      <nav role="navigation" className="Header">
        <Link to="/">
          <div className="Header-logo">
            <img src={logoUrl} />
          </div>
        </Link>

        {minimal || (
          <div className="Header-nav">
            <div className="Header-nav-group">
              <NavLink to="/about-us">About Us</NavLink>

              {/* <NavLink to="/gift/redeem">Redeem a Gift</NavLink>

              <NavLink to="/gift">Give a Gift Card</NavLink> */}
            </div>
            {/* <div className="Header-nav-group">
              <NavLink className="Header-nav-bookLink" to="/pre-checkout">
                Build a Book
              </NavLink>
              <NavLink className="Header-nav-posterLink" to="/checkout/poster">
                Make a Poster
              </NavLink>
            </div> */}
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
