import './styles.scss';

import * as React from 'react';
import { CancelToken } from 'axios';

import request from 'utils/request';

interface Props {}

type State = {
  status: null | 'pending' | 'success' | 'error';
  email: string;
};

export class NewsletterForm extends React.PureComponent<Props, State> {
  static displayName = 'NewsletterForm';

  requestSource:
    | { token: CancelToken; cancel(arg0?: string | null): void }
    | null
    | undefined;

  state = {
    status: null as null | 'pending' | 'success' | 'error',
    email: ''
  };

  componentWillUnmount() {
    if (this.requestSource != null) {
      this.requestSource.cancel();
    }
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.setState({ status: 'pending' });

    // @ts-expect-error: Included types seem to be incorrect for CancelToken export
    this.requestSource = CancelToken.source();

    request
      .post(
        '/api/newsletter_subscriptions',
        {
          email: this.state.email,
          source: 'newsletter_form'
        },
        { cancelToken: this.requestSource!.token }
      )
      .then(
        () => {
          this.setState({ status: 'success', email: '' });
        },
        () => {
          this.setState({ status: 'error' });
        }
      );
  };

  submitText() {
    switch (this.state.status) {
      case 'pending': {
        return 'Submitting…';
      }

      case 'success': {
        return 'Success!';
      }

      default: {
        return 'Sign Up';
      }
    }
  }

  render() {
    const inputDisabled = this.state.status === 'pending';

    return (
      <div className="newsletter-sign-up">
        <h2>Let's stay in touch!</h2>

        <form onSubmit={this.handleSubmit}>
          <input
            type="email"
            placeholder="Email Address"
            value={this.state.email}
            disabled={inputDisabled}
            required
            onChange={event =>
              this.setState({ email: event.currentTarget.value })
            }
          />

          <button
            type="submit"
            disabled={this.state.email.length === 0 || inputDisabled}
          >
            {this.submitText()}
          </button>
        </form>
      </div>
    );
  }
}

export default NewsletterForm;
