import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';

import Book from 'modules/book';
import { State } from 'bundles/application/store';

import BookBuilderField from 'components/book-builder/field';
import BookBuilderInput from 'components/book-builder/input';
import BookBuilderTextArea from 'components/book-builder/text-area';

type Props = {
  name: string;
};

export class WelcomeMessage extends React.PureComponent<Props> {
  node: null | HTMLElement;

  static fieldKeys(): Array<string> {
    return ['personalNote', 'nameOfParents'];
  }

  componentDidMount() {
    if (this.node != null) {
      const input = this.node.querySelector('textarea');

      if (input != null) {
        input.focus();
      }
    }
  }

  render() {
    return (
      <div
        className="BookBuilderWelcomeMessage"
        ref={node => {
          this.node = node;
        }}
      >
        <form>
          <div className="BookBuilderWelcomeMessage-textarea">
            <div className="BookBuilderWelcomeMessage-textarea-salutation">
              Dear {this.props.name},
            </div>
            <BookBuilderField
              block={true}
              label="Personal Note for Inside Cover"
            >
              <BookBuilderTextArea keyPath="personalNote" />
            </BookBuilderField>
          </div>
          <div className="BookBuilderWelcomeMessage-sign-off">
            <span className="non-input-text">Love,</span>
            <BookBuilderField label="From">
              <BookBuilderInput
                keyPath="nameOfParents"
                testId="nameOfParents"
              />
            </BookBuilderField>
          </div>
          <div className="BookBuilderWelcomeMessage-warning">
            Everything you type will be included in the book exactly as entered.
            Please check capitalization, spelling and&nbsp;punctuation!
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName
  };
}

export default connect(mapStateToProps)(WelcomeMessage);
