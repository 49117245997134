import React from 'react';
import Header from './Header';
import Footer from './Footer';

interface Props {
  minimal?: boolean;
}

const Layout: React.FC<Props> = props => {
  const { minimal, children } = props;

  return (
    <>
      <Header minimal={minimal} />

      <main>{children}</main>

      <Footer minimal={minimal} />
    </>
  );
};

export default Layout;
