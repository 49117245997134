import { every, get } from 'lodash';

import { OperationModule } from 'utils/operations';

import setAttribute from './operations/set-attribute';
import load from './operations/load';
import update from './operations/update';
import complete from './operations/complete';
import toggleIncludeMatchingPoster from './operations/toggle-include-matching-poster';
import toggleAddressesAreTheSame from './operations/toggle-addresses-are-the-same';
import toggleSubscribeToEmailList from './operations/toggle-subscribe-to-email-list';
import updateDiagnostics from './operations/update-diagnostics';

import {
  AsyncStatus,
  Address,
  PaymentMethod,
  GiftCertificateVariant
} from 'types';

export type State = {
  readonly loadStatus: AsyncStatus;
  readonly updateStatus: AsyncStatus;
  readonly completeStatus: AsyncStatus;
  readonly paymentMethod: null | PaymentMethod;
  readonly quantity: number;
  readonly promotionCode: string;
  readonly giftCertificateCode: string;
  readonly giftCertificateVariant: null | GiftCertificateVariant;
  readonly giftCertificateMessage: string;
  readonly email: string;
  readonly shipAddress: Address;
  readonly billAddress: Address;
  readonly adjustments: Array<{
    readonly id: number;
    readonly amount: string;
    readonly description: string;
    readonly promotionCode: string | null | undefined;
  }>;
  readonly taxTotal: string | null | undefined;
  readonly total: string | null | undefined;
  readonly includeMatchingPoster: boolean;
  readonly addressesAreTheSame: boolean;
  readonly subscribeToEmailList: boolean;
  readonly errors: Array<string>;
  readonly completeLater: boolean;
};

class Checkout extends OperationModule {
  static operations = [
    setAttribute,
    load,
    update,
    complete,
    toggleIncludeMatchingPoster,
    toggleAddressesAreTheSame,
    toggleSubscribeToEmailList,
    updateDiagnostics
  ];

  static initialState: State = {
    loadStatus: null,
    updateStatus: null,
    completeStatus: null,
    paymentMethod: null,
    quantity: 1,
    promotionCode: '',
    giftCertificateCode: '',
    giftCertificateVariant: null,
    giftCertificateMessage: '',
    email: '',
    billAddress: buildAddress(),
    shipAddress: buildAddress(),
    adjustments: [],
    taxTotal: null,
    total: null,
    includeMatchingPoster: false,
    addressesAreTheSame: true,
    subscribeToEmailList: true,
    errors: [],
    completeLater: false
  };

  setAttributeAction = setAttribute.actionCreator;
  loadAction = load.actionCreator;
  updateAction = update.actionCreator;
  completeAction = complete.actionCreator;
  toggleIncludeMatchingPosterAction = toggleIncludeMatchingPoster.actionCreator;
  toggleAddressesAreTheSameAction = toggleAddressesAreTheSame.actionCreator;
  toggleSubscribeToEmailListAction = toggleSubscribeToEmailList.actionCreator;
  updateDiagnosticsAction = updateDiagnostics.actionCreator;

  getAll(state: { checkout: State }): State {
    return state.checkout;
  }

  getBillAddress(state: { checkout: State }): Address {
    return this.getAll(state).billAddress;
  }

  getShipAddress(state: { checkout: State }): Address {
    return this.getAll(state).shipAddress;
  }

  getValueForKeyPath(state: { checkout: State }, keyPath: string) {
    return get(this.getAll(state), keyPath) || '';
  }

  getOrderIsValid(state: { checkout: State }): boolean {
    const checkoutState = this.getAll(state);

    if (!validatePresence(checkoutState.email)) {
      return false;
    }

    const paymentRequired =
      checkoutState.total && parseFloat(checkoutState.total) > 0;

    if (checkoutState.giftCertificateVariant === 'digital') {
      return !paymentRequired || addressIsValid(checkoutState.billAddress);
    }

    if (checkoutState.giftCertificateVariant === 'physical') {
      const billAddressValid =
        !paymentRequired || addressIsValid(checkoutState.billAddress);

      const shipAddressValid =
        (paymentRequired && checkoutState.addressesAreTheSame) ||
        addressIsValid(checkoutState.shipAddress);

      return billAddressValid && shipAddressValid;
    }

    if (!paymentRequired) {
      return addressIsValid(checkoutState.shipAddress);
    }

    if (checkoutState.paymentMethod === 'credit-card') {
      return (
        addressIsValid(checkoutState.shipAddress) &&
        (checkoutState.addressesAreTheSame ||
          addressIsValid(checkoutState.billAddress))
      );
    }

    if (checkoutState.paymentMethod === 'gift-certificate') {
      return (
        addressIsValid(checkoutState.shipAddress) &&
        validatePresence(checkoutState.giftCertificateCode)
      );
    }

    return false;
  }
}

function validatePresence(string: string | null | undefined) {
  return string != null && /[^\s]+/.test(string);
}

function addressIsValid(address: Address): boolean {
  return every(
    ['name', 'address1', 'locality', 'region', 'postalCode'],
    (key: string) => {
      return validatePresence(address[key]);
    }
  );
}

function buildAddress(): Address {
  if (process.env.NODE_ENV === 'development') {
    return {
      name: 'Jane Doe',
      address1: '1307 Saint Paul St',
      address2: '',
      company: '',
      locality: 'Baltimore',
      region: 'MD',
      postalCode: '21201',
      phone: '1234567890'
    };
  }

  return {
    name: '',
    address1: '',
    address2: '',
    company: '',
    locality: '',
    region: '',
    postalCode: '',
    phone: ''
  };
}

export default new Checkout();
