import { get } from 'lodash';
import { put, call } from 'redux-saga/effects';

import { AsyncOperation } from 'utils/operations';
import request from 'utils/request';
import Book from 'modules/book';
import { State } from '../index';
import { AsyncStatus } from 'types';

type Action = {
  type: 'CHECKOUT/LOAD';
  status: AsyncStatus;
  payload: { token: string | null | undefined };
};

class Load extends AsyncOperation {
  static actionType = 'CHECKOUT/LOAD';

  actionCreator(token?: string) {
    return {
      type: 'CHECKOUT/LOAD',
      status: null,
      payload: { token }
    };
  }

  reducer(state: State, action: Action): State {
    switch (action.status) {
      case 'pending':
      case 'error': {
        return {
          ...state,
          loadStatus: action.status
        };
      }

      case 'success': {
        return {
          ...state,
          email: get(action, 'payload.order.email') || '',
          loadStatus: action.status,
          quantity: get(action, 'payload.order.book.quantity') ?? 1,
          total: get(action, 'payload.order.total', null),
          adjustments: get(action, 'payload.order.adjustments', []),
          includeMatchingPoster:
            get(action, 'payload.order.includeMatchingPoster') ?? false
        };
      }
    }

    return state;
  }

  *saga(action: any): Generator<any, any, any> {
    if (action.status === null) {
      yield this.putPending();

      try {
        const result = yield call(this.request, action);

        if (result != null && result.order.book != null) {
          yield put(Book.setBookData(result.order.book.customData));
        }

        yield this.putSuccess(result);
      } catch (error) {
        yield this.putError(error);
      }
    }
  }

  request(action: Action) {
    let url = '/api/current-order';

    if (action.payload.token != null) {
      url += `?token=${action.payload.token}`;
    }

    return request.get(url).then(({ data }) => data);
  }
}

export default new Load();
