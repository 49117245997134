/**
 * A simple class for providing different forms of pronouns, specifically suited to our needs for
 * the book author.
 *
 * This and the adjacent class could be merged into one, more comprehensive one, but we just don't
 * need it.
 */
export class AuthorPronoun {
  _name: 'singular' | 'plural';

  constructor(name: 'singular' | 'plural' | null) {
    this._name = name || 'plural';
  }

  nominative(): string {
    if (this._name === 'singular') {
      return 'I';
    } else {
      return 'we';
    }
  }

  objective(): string {
    if (this._name === 'singular') {
      return 'me';
    } else {
      return 'us';
    }
  }
}
