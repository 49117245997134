import * as React from 'react';

export class HomepageVideo extends React.PureComponent {
  static displayName = 'HomepageVideo';

  node: HTMLIFrameElement | null | undefined;

  render() {
    return (
      <iframe
        ref={node => (this.node = node)}
        allowFullScreen={true}
        frameBorder="0"
        src="https://player.vimeo.com/video/284734568?title=0&byline=0&portrait=0&loop=1"
        allow="autoplay; encrypted-media"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      />
    );
  }
}

export default HomepageVideo;
