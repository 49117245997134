export default function trySessionStorage<R>(func: () => R): void | R {
  try {
    return func();
  } catch (error) {
    // Fail silently if sessionStorage isn't supported
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
