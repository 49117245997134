import './Home.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';

import { bookPrice, posterPrice } from 'environment';
import Layout from 'components/shared/Layout';
import HomepageVideo from 'components/homepage-video';

import bookPriceBubbleUrl from 'assets/images/homepage/price-bubble_book.png';
import posterPriceBubbleUrl from 'assets/images/homepage/price-bubble_poster.png';

import heroProductsUrl, {
  dimensions as heroProductsDimensions
} from 'assets/images/homepage/little-book-of-you-book-and-poster.png';

import nurseryPosterUrl546, {
  dimensions as nurseryPosterDimensions
} from 'assets/images/homepage/little-book-of-you-poster-nursery-546.jpg';

import nurseryPosterUrl1092 from 'assets/images/homepage/little-book-of-you-poster-nursery-1092.jpg';

import nurseryPosterUrl2184 from 'assets/images/homepage/little-book-of-you-poster-nursery-2184.jpg';

import bespokeBookUrl, {
  dimensions as bespokeBookDimensions
} from 'assets/images/homepage/little-book-of-you-bespoke-spread.png';

import threePostersUrl, {
  dimensions as threePostersDimensions
} from 'assets/images/homepage/little-book-of-you-posters.png';

import paperAirplaneIconUrl, {
  dimensions as paperAirplaneIconDimensions
} from 'assets/images/homepage/highlight-icon-01.png';

import awardPodiumIconUrl, {
  dimensions as awardPodiumIconDimensions
} from 'assets/images/homepage/highlight-icon-02.png';

import speedBunnyIconUrl, {
  dimensions as speedBunnyIconDimensions
} from 'assets/images/homepage/highlight-icon-03.png';

import lightBulbIconUrl, {
  dimensions as lightBulbIconDimensions
} from 'assets/images/homepage/highlight-icon-04.png';

import giftTeaserUrl, {
  dimensions as giftTeaserDimensions
} from 'assets/images/homepage/little-book-of-you-gift-teaser.png';

import feature1Url, {
  dimensions as feature1Dimensions
} from 'assets/images/homepage/01-little-book-of-you-feature.jpg';

import feature2Url, {
  dimensions as feature2Dimensions
} from 'assets/images/homepage/02-little-book-of-you-feature.jpg';

import feature3Url, {
  dimensions as feature3Dimensions
} from 'assets/images/homepage/03-little-book-of-you-feature.jpg';

const Home: React.FC = () => {
  return (
    <Layout>
      <div className="Home-heroBlock">
        <div className="Home-heroBlock-text">
          <div className="Home-heroBlock-title">
            Unique and Custom Creations to celebrate your&nbsp;baby
          </div>
          <div className="Home-heroBlock-links">
            {/* <div>
              <Link className="Home-heroBlock-links-button" to="/pre-checkout">
                Build a Book
                <span className="Home-heroBlock-links-button-price">
                  &nbsp;&mdash;&nbsp;${Math.floor(bookPrice)}.
                  {(bookPrice % 1).toFixed(2).substring(2)}
                </span>
              </Link>

              <Link
                className="Home-heroBlock-links-button"
                to="/checkout/poster"
              >
                Make a Poster
                <span className="Home-heroBlock-links-button-price">
                  &nbsp;&mdash;&nbsp;${Math.floor(posterPrice)}.
                  {(posterPrice % 1).toFixed(2).substring(2)}
                </span>
              </Link>
            </div>

            <Link className="Home-heroBlock-links-textLink" to="/gift">
              Give as a Gift
            </Link> */}
          </div>
        </div>
        <div className="Home-heroBlock-graphic">
          <div className="Home-heroBlock-graphic-bookPrice-wrapper">
            <img
              className="Home-heroBlock-graphic-bookPrice-bubble"
              src={bookPriceBubbleUrl}
            />
            <div className="Home-heroBlock-graphic-bookPrice">
              ${Math.floor(bookPrice)}
              <sup>{(bookPrice % 1).toFixed(2).substring(2)}</sup>
            </div>
          </div>
          <div className="Home-heroBlock-graphic-posterPrice-wrapper">
            <img
              className="Home-heroBlock-graphic-posterPrice-bubble"
              src={posterPriceBubbleUrl}
            />
            <div className="Home-heroBlock-graphic-posterPrice">
              ${Math.floor(posterPrice)}
              <sup>{(posterPrice % 1).toFixed(2).substring(2)}</sup>
            </div>
          </div>
          <img
            alt="book spread and framed poster"
            src={heroProductsUrl}
            {...heroProductsDimensions}
            className="Home-heroBlock-graphic-image"
          />
        </div>
      </div>

      <div className="Home-posterBlockOne">
        <div className="homepage-color-block-text">
          <div className="homepage-color-block-title">
            Light up the room with a personalized&nbsp;poster
          </div>

          <div className="homepage-color-block-description">
            Mark the arrival of a little one with custom artwork
          </div>

          {/* <Link className="homepage-color-block-button" to="/checkout/poster">
            Customize Yours Now
          </Link> */}
        </div>

        <img
          alt="custom poster in a nursery"
          src={nurseryPosterUrl2184}
          srcSet={[
            `${nurseryPosterUrl2184} 2184w`,
            `${nurseryPosterUrl1092} 1092w`,
            `${nurseryPosterUrl546} 546w`
          ].join(', ')}
          sizes="(max-width: 1049px) 100vw, 50vw"
          {...nurseryPosterDimensions}
          loading="lazy"
          className="Home-posterBlockOne-image"
        />
      </div>

      <div className="Home-bookBlock">
        <img
          alt="bespoke book illustration spread"
          src={bespokeBookUrl}
          {...bespokeBookDimensions}
          loading="lazy"
          className="Home-bookBlock-image"
        />
        <div className="homepage-color-block-text">
          <div className="homepage-color-block-title">Build a Bespoke Book</div>

          <div className="homepage-color-block-description">
            32 beautiful pages that tell your child's special story
          </div>

          {/* <Link className="homepage-color-block-button" to="/pre-checkout">
            Customize Yours Now
          </Link> */}
        </div>
      </div>

      <div className="Home-posterBlockTwo">
        <div className="homepage-color-block-text">
          <div className="homepage-color-block-title">
            Celebrate New Beginnings
          </div>
          <div className="homepage-color-block-description">
            Create a timeless memento for your baby's nursery with all the most
            important details
          </div>
          {/* <Link className="homepage-color-block-button" to="/checkout/poster">
            Make a Poster
          </Link> */}
        </div>
        <img
          alt="three custom framed posters"
          src={threePostersUrl}
          {...threePostersDimensions}
          loading="lazy"
          className="Home-posterBlockTwo-image"
        />
      </div>

      <div className="Home-videoBlock">
        <div className="homepage-color-block-text">
          <div className="homepage-color-block-title">
            Celebrate your baby’s arrival
          </div>

          <div className="homepage-color-block-description">
            Capture your most cherished memories by creating a one-of-a-kind
            book
          </div>

          {/* <a className="homepage-color-block-button" href="/pre-checkout">
            Build Your Book
          </a> */}
        </div>

        <div className="homepage-video-block-embed-wrapper">
          <div
            id="homepage-video-container"
            style={{ padding: '100% 0 0 0', position: 'relative' }}
          >
            <HomepageVideo />
          </div>
        </div>
      </div>

      <div className="homepage-sample-spread">
        <div className="homepage-sample-spread-title">
          <span className="blue-text">Bedtime</span> just became even more&nbsp;
          <span className="purple-text">special</span>!
        </div>

        <div className="homepage-sample-spread-description">
          See your baby's story come to life in 32 beautiful illustrations
          custom-tailored to your family
        </div>

        <a
          className="homepage-sample-spread-view-sample-button"
          href="https://s3.us-east-2.amazonaws.com/lboy-production/downloads/little-book-of-you-sample.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Preview Sample
        </a>

        <div className="homepage-sample-image" role="img" />
      </div>

      <div className="homepage-highlights">
        <div className="homepage-highlight">
          <div className="homepage-highlight-icon">
            <img
              alt="paper airplane icon"
              src={paperAirplaneIconUrl}
              {...paperAirplaneIconDimensions}
              loading="lazy"
            />
          </div>

          <div className="homepage-highlight-description">Free Shipping</div>
        </div>

        <div className="homepage-highlight">
          <div className="homepage-highlight-icon">
            <img
              alt="award podium icon"
              src={awardPodiumIconUrl}
              {...awardPodiumIconDimensions}
              loading="lazy"
            />
          </div>

          <div className="homepage-highlight-description">
            For Ages 0-11 (and new babies on the&nbsp;way!)
          </div>
        </div>

        <div className="homepage-highlight">
          <div className="homepage-highlight-icon">
            <img
              alt="speedy bunny icon"
              src={speedBunnyIconUrl}
              {...speedBunnyIconDimensions}
              loading="lazy"
            />
          </div>

          <div className="homepage-highlight-description">
            Fill it out in 15 minutes or&nbsp;less
          </div>
        </div>

        <div className="homepage-highlight">
          <div className="homepage-highlight-icon">
            <img
              alt="light bulb icon"
              src={lightBulbIconUrl}
              {...lightBulbIconDimensions}
              loading="lazy"
            />
          </div>

          <div className="homepage-highlight-description">
            A Super Thoughtful&nbsp;Gift!
          </div>
        </div>
      </div>

      <div className="homepage-gift-block">
        <div className="homepage-color-block-text">
          <div className="homepage-color-block-title">
            A perfect gift for new or expecting parents
          </div>

          <div className="homepage-color-block-description">
            Our Gift Boxes are the perfect gift for baby showers; parents can
            visit our site after their little one arrives to personalize their
            gift.
          </div>

          {/* <Link className="homepage-gift-block-button" to="/gift">
            Purchase a Gift
          </Link> */}
        </div>

        <div className="homepage-gift-block-image-wrapper">
          <img
            className="homepage-gift-block-image"
            alt="Little Book of You wrapped in a ribbon"
            src={giftTeaserUrl}
            {...giftTeaserDimensions}
            loading="lazy"
          />
        </div>
      </div>

      <div className="homepage-features">
        <div className="homepage-features-title">
          What We <span className="pink-text">Promise</span> You
        </div>
        <div className="homepage-features-wrapper">
          <div className="homepage-feature">
            <div className="homepage-feature-image">
              <img
                alt="book spine and pages detail"
                src={feature1Url}
                {...feature1Dimensions}
                loading="lazy"
              />
            </div>
            <div className="homepage-feature-subhead">
              <span className="purple-text">Beautiful</span> Quality
            </div>
            <div className="homepage-feature-text">
              Rich in design and made to last, our hardcover books and posters
              are made with the highest quality paper and materials intended to
              be loved for many years to come.
            </div>
          </div>
          <div className="homepage-feature">
            <div className="homepage-feature-image">
              <img
                alt="parents reading to a baby"
                src={feature3Url}
                {...feature3Dimensions}
                loading="lazy"
              />
            </div>
            <div className="homepage-feature-subhead">
              Magically <span className="yellow-text">Personalized</span>
            </div>
            <div className="homepage-feature-text">
              Our books and posters include all the most wonderful and fun
              details about your child's arrival. The result is pure magic - a
              joyous celebration of new life!
            </div>
          </div>
          <div className="homepage-feature">
            <div className="homepage-feature-image">
              <img
                alt="giving a gift at a baby shower"
                src={feature2Url}
                {...feature2Dimensions}
                loading="lazy"
              />
            </div>
            <div className="homepage-feature-subhead">
              An <span className="blue-text">Inspired</span> Gift
            </div>
            <div className="homepage-feature-text">
              Not your average onesie. Our gifts are one-of-a-kind, sure to
              stand-out from the crowd and be cherished for a lifetime.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
