import { range } from 'lodash';

import { BookData } from 'types';

export default function deserializeBookData(value?: BookData | null): BookData {
  if (value == null || !isObject(value)) {
    return initialState;
  }

  value = {
    ...initialState,
    ...value
  };

  if (value.birthday != null) {
    value.birthday = new Date(value.birthday);
  }

  value.peoplePresentAtBirth = deserializePeoplePresentAtBirth(
    value.peoplePresentAtBirth
  );

  value.familyTreeMembers = deserializeFamilyTreeMembers(
    value.familyTreeMembers
  );

  return value;
}

function isObject(value: unknown): boolean {
  return typeof value === 'object' && value != null;
}

function deserializePeoplePresentAtBirth(
  value: unknown
): Array<{ name: string }> {
  const arrayValue = Array.isArray(value) ? value : [];

  return range(8).map((index: number) => {
    const item = arrayValue[index];

    if (isObject(item) && typeof item.name === 'string') {
      return item;
    } else {
      return { name: '' };
    }
  });
}

function deserializeFamilyTreeMembers(
  value: unknown
): Array<{ name: string; relationship: string }> {
  const arrayValue = Array.isArray(value) ? value : [];

  return range(16).map((index: number) => {
    const item = arrayValue[index];

    if (
      isObject(item) &&
      typeof item.name === 'string' &&
      typeof item.relationship === 'string'
    ) {
      return item;
    } else {
      return { name: '', relationship: '' };
    }
  });
}

const initialState: BookData = {
  bookStyle: null,
  fullName: '',
  shortName: '',
  authorPronoun: null,
  birthday: new Date(new Date().getFullYear(), 0, 1),
  nameOfParents: '',
  birthLocationName: '',
  birthPlace: '',
  birthWeightLb: null,
  birthWeightOz: 0,
  birthHeight: null,
  deliveryDoctorName: '',
  firstAddressStreet: '',
  firstAddressLocality: '',
  firstAddressRegion: '',
  firstAddressPostalCode: '',
  nameExplanation: '',
  familyTreeMembers: range(16).map(() => ({ name: '', relationship: '' })),
  peoplePresentAtBirth: range(8).map(() => ({ name: '' })),
  birthDayWeather: '',
  personalNote: '',
  nickname1: '',
  nickname2: '',
  nickname3: '',
  giftMessage: ''
};
