import './Footer.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';

import NewsletterForm from 'components/newsletter-form';

interface Props {
  minimal?: boolean;
}

const Footer: React.FC<Props> = props => {
  const { minimal } = props;

  return (
    <footer>
      {minimal || (
        <>
          {/* <div className="Footer-buttons">
            <Link className="Footer-buttons-gift" to="/gift">
              Give a Magical Gift
            </Link>

            <Link className="Footer-buttons-book" to="/pre-checkout">
              Create Your Own Book
            </Link>

            <Link className="Footer-buttons-poster" to="/checkout/poster">
              Make a Poster
            </Link>
          </div> */}

          <div className="Footer">
            <div className="Footer-links">
              <div className="Footer-links-column">
                <Link to="/about-us">About Us</Link>
                <Link to="/checkout/book?gift-certificate=1">
                  Redeem a Gift
                </Link>
                <Link to="/gift">Give a Gift Card</Link>
                <Link className="navigation-button" to="/pre-checkout">
                  Create a Custom Book
                </Link>
                <Link className="navigation-button" to="/checkout/poster">
                  Create a Custom Poster
                </Link>
              </div>

              <div className="Footer-links-column">
                <Link to="/faq">FAQ</Link>
                <Link to="/terms-of-service">Terms of Service</Link>
                <a href="https://www.facebook.com/littlebookofyou/">Facebook</a>
                <a href="https://www.instagram.com/littlebookofyou/">
                  Instagram
                </a>
                <div className="contact">
                  Contact Us at{' '}
                  <a href="mailto:info@littlebookofyou.com">
                    info@littlebookofyou.com
                  </a>
                  <script id="mail_to-bvqo2ebl">
                    eval(decodeURIComponent('%76%61%72%20%73%63%72%69%70%74%20%3d%20%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%27%6d%61%69%6c%5f%74%6f%2d%62%76%71%6f%32%65%62%6c%27%29%3b%76%61%72%20%61%20%3d%20%64%6f%63%75%6d%65%6e%74%2e%63%72%65%61%74%65%45%6c%65%6d%65%6e%74%28%27%61%27%29%3b%61%2e%73%65%74%41%74%74%72%69%62%75%74%65%28%27%68%72%65%66%27%2c%20%27%6d%61%69%6c%74%6f%3a%69%6e%66%6f%40%6c%69%74%74%6c%65%62%6f%6f%6b%6f%66%79%6f%75%2e%63%6f%6d%27%29%3b%61%2e%61%70%70%65%6e%64%43%68%69%6c%64%28%64%6f%63%75%6d%65%6e%74%2e%63%72%65%61%74%65%54%65%78%74%4e%6f%64%65%28%27%69%6e%66%6f%40%6c%69%74%74%6c%65%62%6f%6f%6b%6f%66%79%6f%75%2e%63%6f%6d%27%29%29%3b%73%63%72%69%70%74%2e%70%61%72%65%6e%74%4e%6f%64%65%2e%69%6e%73%65%72%74%42%65%66%6f%72%65%28%61%2c%73%63%72%69%70%74%29%3b'))
                  </script>
                </div>
              </div>
            </div>

            <div id="newsletter-form-container">
              <NewsletterForm />
            </div>
          </div>
        </>
      )}

      <div className="Footer-copyright">
        © Copyright {new Date().getFullYear()} Little Book of You
      </div>
    </footer>
  );
};

export default Footer;
