import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import { range } from 'lodash';

import Book from 'modules/book';
import { State as ReduxState } from 'bundles/application/store';

import { namePossesive } from 'utils/names';

import BookBuilderParagraph from 'components/book-builder/paragraph';
import BookBuilderConnectedInput from 'components/book-builder/connected-input';
import familyTreeExampleUrl from 'assets/images/book-builder/family-tree-example.jpg';

type Props = {
  name: string;
  familyTreeMembers: Array<{ name: string; relationship: string }>;
};

type State = {
  showExample: boolean;
};

const placeholders = [
  { name: 'ex. Jennifer Smith', relationship: 'ex. Mother' },
  { name: 'ex. Timothy Smith', relationship: 'ex. Father' },
  { name: 'ex. Daniel & Marge Smith', relationship: 'ex. Grandma & Grandpa' },
  { name: 'ex. Michelle & George Lewis', relationship: 'ex. Aunt & Uncle' }
].concat(
  range(12).map(() => {
    return { name: 'Family Member Name', relationship: 'Relationship' };
  })
);

export class FamilyTree extends React.PureComponent<Props, State> {
  node: null | HTMLElement;

  state = {
    showExample: false
  };

  static fieldKeys(): Array<string> {
    return ['familyTreeMembers'];
  }

  toggleExample = (visible: boolean) => {
    this.setState({ showExample: visible });
  };

  render() {
    return (
      <div
        className="BookBuilderFamilyTree"
        ref={node => {
          this.node = node;
        }}
      >
        <div>
          <h3>{namePossesive(this.props.name)} Family Tree</h3>
          <div className="caption">
            4-32 immediate family members recommended. Each space can
            accommodate 1 or 2 people.{' '}
            <a
              className="BookBuilderFamilyTree-example-link"
              onClick={this.toggleExample.bind(null, true)}
            >
              View&nbsp;example.
            </a>
          </div>
        </div>

        <BookBuilderParagraph>
          <div className="BookBuilderFamilyTree-table">
            <div className="BookBuilderFamilyTree-table-row">
              <div className="BookBuilderFamilyTree-table-header-name">
                Family Member Name
              </div>
              <div className="BookBuilderFamilyTree-table-header-relationship">
                Relationship
              </div>
            </div>

            {range(16).map(index => {
              return (
                <div key={index} className="BookBuilderFamilyTree-table-row">
                  <div className="BookBuilderFamilyTree-table-cell-name">
                    <BookBuilderConnectedInput
                      autoFocus={index === 0}
                      keyPath={`familyTreeMembers.${index}.name`}
                      placeholder={placeholders[index].name}
                      testId={`familyTreeMemberName-${index}`}
                      maxLength={38}
                    />
                  </div>
                  <div className="BookBuilderFamilyTree-table-cell-relationship">
                    <BookBuilderConnectedInput
                      keyPath={`familyTreeMembers.${index}.relationship`}
                      placeholder={placeholders[index].relationship}
                      testId={`familyTreeMemberRelationship-${index}`}
                      maxLength={32}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </BookBuilderParagraph>

        {this.state.showExample && (
          <div className="BookBuilderFamilyTree-example">
            <div
              className="BookBuilderFamilyTree-example-mask"
              onClick={this.toggleExample.bind(null, false)}
            />
            <button
              type="button"
              className="BookBuilderFamilyTree-example-close"
              onClick={this.toggleExample.bind(null, false)}
            >
              &times;
            </button>
            <div className="BookBuilderFamilyTree-example-content">
              <img src={familyTreeExampleUrl} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName,
    familyTreeMembers: bookData.familyTreeMembers
  };
}

export default connect(mapStateToProps, null)(FamilyTree);
