import dotProp from 'dot-prop-immutable';
import { Operation } from 'utils/operations';

import { State } from '../index';

type Action = {
  type: 'CHECKOUT/SET_ATTRIBUTE';
  payload: {
    keyPath: string;
    value: unknown;
  };
};

class SetAttribute extends Operation {
  static actionType = 'CHECKOUT/SET_ATTRIBUTE';

  actionCreator(keyPath: string, value: unknown) {
    return {
      type: 'CHECKOUT/SET_ATTRIBUTE',
      payload: { keyPath, value }
    };
  }

  reducer(state: State, action: Action): State {
    const { keyPath, value } = action.payload;

    state = dotProp.set(state, keyPath, value);

    if (keyPath === 'paymentMethod' && value === 'gift-certificate') {
      state = dotProp.set(state, 'quantity', 1);
    }

    return state;
  }
}

export default new SetAttribute();
