import { Operation } from 'utils/operations';
import { State } from '../index';

class ToggleIncludeMatchingPoster extends Operation {
  static actionType = 'CHECKOUT/TOGGLE_INCLUDE_MATCHING_POSTER';

  reducer(state: State): State {
    return {
      ...state,
      includeMatchingPoster: !state.includeMatchingPoster
    };
  }
}

export default new ToggleIncludeMatchingPoster();
