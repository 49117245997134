import './styles.scss';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Book from 'modules/book';
import { State } from 'bundles/application/store';

import { namePossesive } from 'utils/names';

import BookBuilderParagraph from 'components/book-builder/paragraph';
import BookBuilderField from 'components/book-builder/field';
import BookBuilderInput from 'components/book-builder/input';
import BookBuilderSelect from 'components/book-builder/select';

import states from 'constants/states';

type Props = {
  name: string;
};

export class FirstHome extends PureComponent<Props> {
  node: null | HTMLElement;

  static fieldKeys(): Array<string> {
    return [
      'firstAddressStreet',
      'firstAddressLocality',
      'firstAddressRegion',
      'firstAddressPostalCode'
    ];
  }

  render() {
    return (
      <div
        className="BookBuilderFirstHome"
        ref={node => {
          this.node = node;
        }}
      >
        <form>
          <BookBuilderParagraph>
            <span className="non-input-text">
              {namePossesive(this.props.name)} first home was at
            </span>
            <BookBuilderField label="Street Address" className="comma-after">
              <BookBuilderInput
                autoFocus
                keyPath="firstAddressStreet"
                className="BookBuilderFirstHome-street-address"
                testId="firstAddressStreet"
              />
            </BookBuilderField>
          </BookBuilderParagraph>

          <BookBuilderParagraph>
            <BookBuilderField label="City or Town" className="comma-after">
              <BookBuilderInput
                keyPath="firstAddressLocality"
                testId="firstAddressLocality"
              />
            </BookBuilderField>
            <BookBuilderField label="State">
              <BookBuilderSelect
                keyPath="firstAddressRegion"
                testId="firstAddressRegion"
              >
                <option value="" />
                {states.map(state => {
                  return (
                    <option value={state.name} key={state.abbreviation}>
                      {state.name}
                    </option>
                  );
                })}
              </BookBuilderSelect>
            </BookBuilderField>

            <BookBuilderField label="Zip Code">
              <BookBuilderInput
                keyPath="firstAddressPostalCode"
                className="BookBuilderFirstHome-zip"
                testId="firstAddressPostalCode"
              />
            </BookBuilderField>
          </BookBuilderParagraph>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName
  };
}

export default connect(mapStateToProps)(FirstHome);
