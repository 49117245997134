import './styles.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
  label?: string;
  children?: React.ReactNode;
  block?: boolean;
  small?: boolean;
  className?: string;
  hint?: string;
};

type State = {
  hintIsVisible: boolean;
};

export default class BookBuilderField extends React.PureComponent<
  Props,
  State
> {
  state = {
    hintIsVisible: false
  };
  iconNode: HTMLSpanElement | null | undefined;

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleTouchOutside);
  }

  handleTouchOutside = (event: TouchEvent) => {
    if (event.target !== this.iconNode) {
      this.hideHint();
    }
  };

  showHint = () => {
    this.setState({ hintIsVisible: true });
  };

  hideHint = () => {
    this.setState({ hintIsVisible: false });
  };

  toggleHint = () => {
    this.setState((state: State) => ({
      ...state,
      hintIsVisible: !state.hintIsVisible
    }));
  };

  render() {
    const { children, label, hint } = this.props;

    const classes = classNames(
      'BookBuilderField',
      this.props.className,
      { 'BookBuilderField--block': this.props.block },
      { 'BookBuilderField--small': this.props.small }
    );

    return (
      <span className={classes}>
        {children}
        {label && (
          <div className="BookBuilderField-label">
            {label}
            {hint != null && (
              <span
                className="BookBuilderField-info-icon"
                ref={node => (this.iconNode = node)}
                onMouseEnter={this.showHint}
                onMouseLeave={this.hideHint}
                onTouchEnd={this.toggleHint}
              >
                ?
                {this.state.hintIsVisible && (
                  <span className="BookBuilderField-hint">{hint}</span>
                )}
              </span>
            )}
          </div>
        )}
      </span>
    );
  }
}
