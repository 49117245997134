import './styles.scss';

import React from 'react';
import { range } from 'lodash';
import { connect } from 'react-redux';

import Book from 'modules/book';
import { State } from 'bundles/application/store';

import BookBuilderParagraph from 'components/book-builder/paragraph';
import BookBuilderField from 'components/book-builder/field';
import BookBuilderSelect from 'components/book-builder/select';
import BookBuilderInput from 'components/book-builder/input';
import BookBuilderLengthSelect from 'components/book-builder/length-select';

type Props = {
  name: string;
};

export class BirthDetails extends React.PureComponent<Props> {
  static fieldKeys(): Array<string> {
    return [
      'birthWeightLb',
      'birthWeightOz',
      'birthHeight',
      'deliveryDoctorName',
      'birthLocationName',
      'birthPlace'
    ];
  }

  render() {
    return (
      <div className="BookBuilderBirthDetails">
        <BookBuilderParagraph>
          {this.props.name} weighed
          <BookBuilderField label="Pounds" small={true}>
            <BookBuilderSelect
              numeric
              keyPath="birthWeightLb"
              testId="birthWeightLb"
            >
              <option />
              {range(0, 13).map(value => {
                return (
                  <option value={value} key={value}>
                    {value}
                  </option>
                );
              })}
            </BookBuilderSelect>
          </BookBuilderField>
          <BookBuilderField label="Ounces" small={true}>
            <BookBuilderSelect
              numeric
              keyPath="birthWeightOz"
              testId="birthWeightOz"
            >
              {range(0, 16).map(value => {
                return (
                  <option value={value} key={value}>
                    {value}
                  </option>
                );
              })}
            </BookBuilderSelect>
          </BookBuilderField>
          <span>and was</span>
          <BookBuilderField label="Inches" small={true}>
            <BookBuilderLengthSelect keyPath="birthHeight" />
          </BookBuilderField>
        </BookBuilderParagraph>

        <BookBuilderParagraph>
          <BookBuilderField label="Midwife or Doctor (optional)">
            <BookBuilderInput
              keyPath="deliveryDoctorName"
              testId="deliveryDoctorName"
            />
          </BookBuilderField>
          <span className="non-input-text">helped deliver them at</span>
          <BookBuilderField
            label="Hospital or Place of Birth"
            className="period-after"
          >
            <BookBuilderInput
              keyPath="birthLocationName"
              testId="birthLocationName"
            />
          </BookBuilderField>
        </BookBuilderParagraph>

        <BookBuilderParagraph>
          <span className="non-input-text">located in</span>
          <BookBuilderField
            label="Geographic Location"
            className="period-after"
          >
            <BookBuilderInput
              keyPath="birthPlace"
              placeholder="i.e. Denver, Colorado"
              className="BookBuilderBirthDetails-geographic-location"
              testId="birthPlace"
            />
          </BookBuilderField>
        </BookBuilderParagraph>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName
  };
}

export default connect(mapStateToProps)(BirthDetails);
