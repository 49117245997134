import dotProp from 'dot-prop-immutable';
import { Operation } from 'utils/operations';

import { BookState } from '../index';

type Action = {
  type: 'BOOK/DELETE_BOOK_DATA_VALUE';
  payload: { keyPath: string };
};

class DeleteBookDataValue extends Operation {
  static actionType = 'BOOK/DELETE_BOOK_DATA_VALUE';

  actionCreator(keyPath: string): Action {
    return {
      type: 'BOOK/DELETE_BOOK_DATA_VALUE',
      payload: { keyPath }
    };
  }

  reducer(state: BookState, action: Action): BookState {
    state = dotProp.delete(state, `bookData.${action.payload.keyPath}`);
    state = dotProp.set(state, 'informationVerified', false);

    return state;
  }
}

export default new DeleteBookDataValue();
