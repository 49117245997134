import { get, findIndex } from 'lodash';

import { OperationModule } from 'utils/operations';
import { validateFields } from './validations';
import setBookData from './operations/set-book-data';
import setBookDataValue from './operations/set-book-data-value';
import deleteAttribute from './operations/delete-book-data-value';
import toggleInformationVerified from './operations/toggle-information-verified';
import deserializeBookData from './utils/deserialize-book-data';
import { BookBuilderPages } from 'components/book-builder-pages';

import { BookData } from 'types';

export type BookState = {
  readonly bookData: BookData;
  readonly personalNoteEdited: boolean;
  readonly informationVerified: boolean;
};

class Book extends OperationModule {
  static operations = [
    setBookData,
    setBookDataValue,
    deleteAttribute,
    toggleInformationVerified
  ];

  static initialState: BookState = {
    bookData: deserializeBookData(),
    personalNoteEdited: false,
    informationVerified: false
  };

  setBookData = setBookData.actionCreator;
  setBookDataValueAction = setBookDataValue.actionCreator;
  deleteAttributeAction = deleteAttribute.actionCreator;
  toggleInformationVerifiedAction = toggleInformationVerified.actionCreator;

  getAll(state: { book: BookState }) {
    return state.book;
  }

  getBookDataValue = (state: { book: BookState }, keyPath: string) => {
    const value = get(this.getAll(state), `bookData.${keyPath}`);
    return value == null ? '' : value;
  };

  getBookData = (state: { book: BookState }): BookData => {
    return this.getAll(state).bookData;
  };

  getInformationVerified = (state: { book: BookState }): boolean => {
    return this.getAll(state).informationVerified;
  };

  /**
   * The maximum page in the book builder flow which has 'valid' data.
   *
   * What fields are displayed on what page is the responsibility of the page
   * components. So, unusually, we're importing and using them here.
   */
  getIndexOfLastValidStep = (state: { book: BookState }): number => {
    // find the first invalid page
    const index = findIndex(BookBuilderPages, page => {
      return !validateFields(this.getBookData(state), page.fieldKeys());
    });

    // or return the last index if they're all valid
    if (index === -1) {
      return BookBuilderPages.length;
    }

    return index;
  };

  getBookDataIsValid = (state: { book: BookState }): boolean => {
    return this.getIndexOfLastValidStep(state) === BookBuilderPages.length;
  };
}

export default new Book();
