import './styles.scss';

import React, { Component } from 'react';

import BookBuilderParagraph from 'components/book-builder/paragraph';
import BookBuilderField from 'components/book-builder/field';
import BookBuilderSelect from 'components/book-builder/select';
import BookBuilderInput from 'components/book-builder/input';

export default class TitlePage extends Component {
  static fieldKeys(): Array<string> {
    return ['fullName', 'shortName', 'authorPronoun'];
  }

  render() {
    return (
      <div className="BookBuilderTitlePage">
        <BookBuilderParagraph>
          <BookBuilderField
            label="Parent(s)"
            hint="The story is told from the perspective of the child's parent(s). Select the pronoun that should be used throughout."
          >
            <BookBuilderSelect
              keyPath="authorPronoun"
              className="BookBuilderTitlePage-parent"
              testId="authorPronoun"
            >
              <option value="" />
              <option value="plural">We are</option>
              <option value="singular">I am</option>
            </BookBuilderSelect>
          </BookBuilderField>
          <span className="non-input-text">creating this book for</span>

          <BookBuilderField label="Full Name" className="comma-after">
            <BookBuilderInput
              keyPath="fullName"
              className="BookBuilderTitlePage-full-name"
              testId="fullName"
            />
          </BookBuilderField>
        </BookBuilderParagraph>

        <BookBuilderParagraph>
          <span className="non-input-text">who likes to go by</span>

          <BookBuilderField
            label="Short Name"
            className="period-after"
            hint="Short Name is used throughout the book when refering to the&nbsp;child."
          >
            <BookBuilderInput
              keyPath="shortName"
              className="BookBuilderTitlePage-short-name"
              testId="shortName"
            />
          </BookBuilderField>
        </BookBuilderParagraph>
      </div>
    );
  }
}
