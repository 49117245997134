import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import { range } from 'lodash';

import { toInt } from 'utils/conversions';
import Book from 'modules/book';
import { State, Dispatch } from 'bundles/application/store';
import { BookBuilderSelect } from '../select';

function parseLength(value: number) {
  return {
    inches: Math.floor(value),
    quarters: Math.floor((value * 4) % 4)
  };
}

function makeLength(
  inches: number | null | undefined,
  quarters: number
): null | number {
  if (inches == null) {
    return null;
  }

  return inches + quarters / 4;
}

interface Props {
  keyPath: string;
}

interface ProvidedProps extends Props {
  value: number;
  onChange(value: null | number): void;
}

/**
 * A pair of dropdowns to select a length by inches and quarter inches.
 *
 * Does not include a BuilderField with label.
 *
 * The default export is a connected component like other inputs, or the bare component can be
 * imported and connected to a value and change handler.
 */
export class LengthSelect extends React.PureComponent<ProvidedProps> {
  handleInchesChanged = (value: unknown) => {
    const { quarters } = parseLength(this.props.value);

    this.props.onChange(makeLength(toInt(value), quarters));
  };

  handleQuartersOfInchesChanged = (value: unknown) => {
    const { inches } = parseLength(this.props.value);

    this.props.onChange(makeLength(inches, toInt(value)));
  };

  render() {
    const { inches, quarters } = parseLength(this.props.value);
    return (
      <span className="BookBuilderLengthSelect">
        <BookBuilderSelect
          numeric
          value={String(inches)}
          testId="birthHeightIn"
          onChange={this.handleInchesChanged}
        >
          <option value="" />
          {range(11, 25).map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            );
          })}
        </BookBuilderSelect>

        <BookBuilderSelect
          numeric
          value={String(quarters)}
          testId="birthHeightFraction"
          onChange={this.handleQuartersOfInchesChanged}
        >
          <option value="0">0</option>
          <option value="1">¼</option>
          <option value="2">½</option>
          <option value="3">¾</option>
        </BookBuilderSelect>
      </span>
    );
  }
}

function mapStateToProps(state: State, props: Props) {
  return {
    value: Book.getBookDataValue(state, props.keyPath)
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props) {
  return {
    onChange(value: number | null) {
      dispatch(Book.setBookDataValueAction(props.keyPath, value));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LengthSelect);
