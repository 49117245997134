import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';

import Book from 'modules/book';
import { State as ReduxState } from 'bundles/application/store';

import { namePossesive } from 'utils/names';

import BookBuilderField from 'components/book-builder/field';
import BookBuilderTextArea from 'components/book-builder/text-area';
import BookBuilderInput from 'components/book-builder/input';
import BookBuilderParagraph from 'components/book-builder/paragraph';
import nameExplanationExampleUrl from 'assets/images/book-builder/name-explanation-example.png';

type Props = {
  name: string;
  fullName: string;
  authorPronounString: 'singular' | 'plural' | null;
  nameExplanation: string;
};

type State = {
  showExample: boolean;
};

export class NameExplanation extends React.PureComponent<Props, State> {
  node: null | HTMLElement;

  state = {
    showExample: false
  };

  static fieldKeys(): Array<string> {
    return ['nameExplanation', 'nickname1', 'nickname2', 'nickname3'];
  }
  toggleExample = (visible: boolean) => {
    this.setState({ showExample: visible });
  };

  componentDidMount() {
    if (this.node != null) {
      const input = this.node.querySelector('textarea');

      if (input != null) {
        input.focus();
      }
    }
  }

  renderTextLengthWarning() {
    const length = this.props.nameExplanation.length;
    if (length > 700) {
      return (
        <span className="BookBuilderNameExplanation-length-warning hard-limit">
          Character limit exceeded! Please edit your explanation.
        </span>
      );
    } else if (length > 400) {
      return (
        <span className="BookBuilderNameExplanation-length-warning">
          Longer explanations will appear in smaller text.
        </span>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div
        className="BookBuilderNameExplanation"
        ref={node => {
          this.node = node;
        }}
      >
        <form>
          <BookBuilderParagraph>
            Please describe (in full sentences!) why you chose the name “
            {this.props.fullName}”.{' '}
            <a
              className="BookBuilderNameExplanation-example-link"
              onClick={this.toggleExample.bind(null, true)}
            >
              View&nbsp;example.
            </a>
          </BookBuilderParagraph>

          <BookBuilderParagraph>
            {this.renderTextLengthWarning()}
            <BookBuilderField label="Brief Explanation of Name" block={true}>
              <BookBuilderTextArea
                keyPath="nameExplanation"
                className="BookBuilderNameExplanation-textarea"
                testId="nameExplanation"
              />
            </BookBuilderField>
          </BookBuilderParagraph>

          <BookBuilderParagraph>
            <span className="non-input-text">
              Some of {namePossesive(this.props.name)} nicknames are
            </span>
          </BookBuilderParagraph>

          <BookBuilderParagraph>
            <BookBuilderField label="Nickname" className="comma-after">
              <BookBuilderInput
                keyPath="nickname1"
                className="BookBuilderNameExplanation-nickname"
                placeholder="Pumpkin"
                testId="nickname-1"
              />
            </BookBuilderField>
            <BookBuilderField
              label="Nickname (Optional)"
              className="comma-after"
            >
              <BookBuilderInput
                keyPath="nickname2"
                className="BookBuilderNameExplanation-nickname"
                placeholder="Buddy"
                testId="nickname-2"
              />
            </BookBuilderField>
            <BookBuilderField
              label="Nickname (Optional)"
              className="period-after"
            >
              <BookBuilderInput
                keyPath="nickname3"
                className="BookBuilderNameExplanation-nickname"
                placeholder="Bunny"
                testId="nickname-3"
              />
            </BookBuilderField>
          </BookBuilderParagraph>
        </form>
        <div className="BookBuilderNameExplanation-warning">
          Everything you type will be included in the book exactly as entered.
          Please check capitalization, spelling and&nbsp;punctuation!
        </div>

        {this.state.showExample && (
          <div className="BookBuilderNameExplanation-example">
            <div
              className="BookBuilderNameExplanation-example-mask"
              onClick={this.toggleExample.bind(null, false)}
            />
            <button
              type="button"
              className="BookBuilderNameExplanation-example-close"
              onClick={this.toggleExample.bind(null, false)}
            >
              &times;
            </button>
            <div className="BookBuilderNameExplanation-example-content">
              <img src={nameExplanationExampleUrl} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName,
    fullName: bookData.fullName,
    authorPronounString: bookData.authorPronoun,
    nameExplanation: bookData.nameExplanation ?? ''
  };
}

export default connect(mapStateToProps)(NameExplanation);
