declare global {
  interface Window {
    _refersion?(callback: () => void): void;
    _rfsn?: {
      _addCart(refersionId: string): void;
    };
  }
}

import { call, select, put } from 'redux-saga/effects';
import { get, isFunction } from 'lodash';

import { AsyncOperation } from 'utils/operations';
import request from 'utils/request';
import trySessionStorage from 'utils/try-session-storage';

import Checkout from '../index';
import Book from 'modules/book';

import trackConversion from 'utils/track-conversion';
import updateDiagnostics from './update-diagnostics';

import { notify } from 'utils/bugsnag';

import { Address, BookData } from 'types';
import { State } from '../index';

type Action = {
  type: 'CHECKOUT/COMPLETE';
  status: null | 'pending' | 'success' | 'error';
  payload(): Promise<{ id: string }>;
};

type RequestOptions = {
  quantity: number;
  email: string;
  billAddress: Address;
  shipAddress: Address;
  includeMatchingPoster: boolean;
  promotionCode?: string;
  giftCertificateVariant?: 'digital' | 'physical';
  bookData?: BookData;
  stripeToken?: string;
  giftCertificateCode?: string;
  giftCertificateMessage?: string;
  subscribeToEmailList: boolean;
};

class Complete extends AsyncOperation {
  static actionType = 'CHECKOUT/COMPLETE';

  actionCreator(createToken?: () => Promise<{ id: string }>) {
    return {
      type: 'CHECKOUT/COMPLETE',
      status: null,
      payload: createToken
    };
  }

  reducer(state: State, action: Action): State {
    switch (action.status) {
      case 'pending': {
        return {
          ...state,
          completeStatus: 'pending',
          errors: []
        };
      }

      case 'success': {
        return {
          ...state,
          completeStatus: 'success'
        };
      }

      case 'error': {
        return {
          ...state,
          completeStatus: 'error',
          errors: get(action, 'payload.response.data.errors', [])
        };
      }
    }

    return state;
  }

  request(options: RequestOptions) {
    const order = {
      email: options.email,
      book: {},
      giftCertificate: {},
      billAddress: options.billAddress,
      shipAddress: options.shipAddress,
      payment: {}
    };

    let promotionCode = null;

    if (options.promotionCode != null) {
      promotionCode = options.promotionCode;
    }

    if (options.giftCertificateVariant != null) {
      order.giftCertificate = {
        variant: options.giftCertificateVariant,
        message: options.giftCertificateMessage
      };
    } else {
      order.book = {
        quantity: options.quantity,
        customData: options.bookData
      };
    }

    if (options.stripeToken != null) {
      order.payment = {
        stripeSourceId: options.stripeToken
      };
    } else {
      order.payment = {
        giftCertificateCode: options.giftCertificateCode
      };
    }

    return request
      .put('/api/current-order/complete', {
        order,
        promotionCode,
        subscribeToEmailList: options.subscribeToEmailList
      })
      .then(({ data }) => data);
  }

  *saga(action: Action): Generator<any, any, any> {
    if (action.status === null) {
      yield this.putPending();

      try {
        if (isFunction(action.payload)) {
          // Credit card checkout
          const stripeResult = yield call(action.payload);

          yield this.checkoutRequest(stripeResult.id);
        } else {
          // Gift certificate checkout
          yield this.checkoutRequest();
        }
      } catch (error) {
        // Stripe.js request error

        notify(error);

        yield this.putError(error);
        yield put(updateDiagnostics.actionCreator());
      }
    }
  }

  // TODO: Some of this logic can be shared with "checkoutRequest" in the
  // update operation.
  *checkoutRequest(stripeToken?: string): Generator<any, any, any> {
    const checkoutState: State = yield select(Checkout.getAll);
    const bookData: BookData = yield select(Book.getBookData);

    try {
      let billAddress, shipAddress;

      const paymentRequired =
        checkoutState.total && parseFloat(checkoutState.total) > 0;

      if (checkoutState.giftCertificateVariant == null) {
        // If this is a book checkout, the shipping address is first.

        billAddress = checkoutState.addressesAreTheSame
          ? checkoutState.shipAddress
          : checkoutState.billAddress;

        shipAddress = checkoutState.shipAddress;
      } else {
        // If this is a gift certificate checkout, the billing address is first.

        billAddress = checkoutState.billAddress;

        // If the addresses are the same and payment is required (i.e. we have a
        // bill address), then use the bill address for the ship address.
        shipAddress =
          checkoutState.addressesAreTheSame && paymentRequired
            ? checkoutState.billAddress
            : checkoutState.shipAddress;
      }

      let quantity = checkoutState.quantity;

      if (quantity < 1) {
        quantity = 1;
      }

      const options: RequestOptions = {
        email: checkoutState.email,
        quantity,
        billAddress,
        shipAddress,
        includeMatchingPoster: checkoutState.includeMatchingPoster,
        subscribeToEmailList: checkoutState.subscribeToEmailList
      };

      if (checkoutState.promotionCode.length > 0) {
        options.promotionCode = checkoutState.promotionCode;
      }

      if (checkoutState.giftCertificateVariant != null) {
        options.giftCertificateVariant = checkoutState.giftCertificateVariant;
        options.giftCertificateMessage = checkoutState.giftCertificateMessage;
      } else {
        options.bookData = bookData;
      }

      if (stripeToken != null) {
        options.stripeToken = stripeToken;
      } else {
        options.giftCertificateCode = checkoutState.giftCertificateCode;
      }

      const checkoutResponse = yield call(this.request, options);

      // Clear the stored book data from the checkout process
      trySessionStorage(() => sessionStorage.removeItem('bookState'));

      // Notify Refersion of the sale
      if (window._refersion != null) {
        try {
          window._refersion(() => {
            if (window._rfsn != null) {
              window._rfsn._addCart(checkoutResponse.order.refersionId);
            }
          });
        } catch (error) {
          notify(error);
        }
      }

      trackConversion(checkoutResponse);

      yield this.putSuccess(checkoutResponse);
    } catch (error) {
      // Checkout request error
      yield this.putError(error);
    }
  }
}

export default new Complete();
