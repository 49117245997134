import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { stripePublishableKey } from 'environment';

if (stripePublishableKey == null) {
  throw new Error('Missing Stripe publishable key');
}

const stripePromise = loadStripe(stripePublishableKey);

const StripeElements: React.FC = props => {
  const { children } = props;

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeElements;
