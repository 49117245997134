import './PreCheckout.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';

import Layout from './shared/Layout';

import bookImageUrl, {
  dimensions as bookImageDimensions
} from 'assets/images/homepage/little-book-of-you.png';

import giftBoxImageUrl, {
  dimensions as giftBoxImageDimensions
} from 'assets/images/homepage/little-book-of-you-giftbox.png';

interface Props {}

const PreCheckout: React.FC<Props> = () => {
  return (
    <Layout minimal>
      <div className="PreCheckout">
        <div className="PreCheckout-options">
          <div className="PreCheckout-option-book">
            <div className="PreCheckout-option-book-text">
              <h2 className="PreCheckout-option-heading">Build a Book</h2>
              <p>
                Enter all relevant birth details and create a fully personalized
                book, either for your own child or to give as a gift.
              </p>
              <p>You will need:</p>
              <ul>
                <li>
                  Child’s personal info (see{' '}
                  <Link to="/faq#ordering-1">all questions</Link>)
                </li>
                <li>15 minutes</li>
              </ul>
              <Link className="PreCheckout-option-button" to="/checkout/book">
                Get Started
              </Link>
            </div>

            <img
              className="PreCheckout-option-image"
              alt="Little Book of You"
              src={bookImageUrl}
              {...bookImageDimensions}
            />
          </div>

          <div className="PreCheckout-option-giftCard">
            <div className="PreCheckout-option-giftCard-text">
              <h2 className="PreCheckout-option-heading">
                Buy a Gift Card or Gift&nbsp;Box
              </h2>
              <p>
                The perfect gift for new and expecting parents. Choose from two
                super easy&nbsp;options.
              </p>
              <Link className="PreCheckout-option-button" to="/gift">
                Checkout
              </Link>
            </div>

            <img
              className="PreCheckout-option-giftCard-image"
              alt="gift card"
              src={giftBoxImageUrl}
              {...giftBoxImageDimensions}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PreCheckout;
