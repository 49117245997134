import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Textarea, { TextareaAutosizeProps } from 'react-textarea-autosize';

import Book from 'modules/book';
import { State, Dispatch } from 'bundles/application/store';

interface Props extends Omit<TextareaAutosizeProps, 'ref'> {
  keyPath?: string;
  testId?: string;
}

/**
 * Text area tailored to the book builder.
 *
 * Import the default (connected to store) component and provide a keypath to automatically bind
 * to the book data in the store.
 *
 * Import the component explicity to provide another `value` and `onChange` handler.
 */
export class BookBuilderTextArea extends React.PureComponent<Props> {
  render() {
    const { keyPath, testId, ...textareaProps } = this.props;

    return (
      <Textarea
        {...textareaProps}
        className={classNames('BookBuilderTextArea', textareaProps.className)}
        tabIndex={1}
        data-test-id={testId}
      />
    );
  }
}

function mapStateToProps(state: State, props: Props) {
  if (!props.keyPath) {
    return null;
  }

  return {
    value: Book.getBookDataValue(state, props.keyPath)
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props) {
  return {
    onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
      if (props.keyPath) {
        dispatch(
          Book.setBookDataValueAction(props.keyPath, event.currentTarget.value)
        );
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookBuilderTextArea);
