import './styles.scss';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Book from 'modules/book';
import { State, Dispatch } from 'bundles/application/store';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props extends Omit<HTMLInputElementProps, 'ref'> {
  keyPath: string;
  testId?: string;
}

/**
 * Text field tailored to the book builder.
 *
 * Import the default (connected to store) component and provide a keypath to automatically bind
 * to the checkout data in the store.
 *
 * Import the component explicity to provide another `value` and `onChange` handler.
 */
export class BookBuilderInput extends PureComponent<Props> {
  static defaultProps = {
    autoFocus: false
  };

  render() {
    const { keyPath, className, testId, ...inputProps } = this.props;

    return (
      <input
        type="text"
        tabIndex={1}
        {...inputProps}
        className={classNames('BookBuilderInput', className)}
        data-test-id={testId}
      />
    );
  }
}

function mapStateToProps(state: State, props: Props) {
  return {
    value: Book.getBookDataValue(state, props.keyPath)
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props) {
  return {
    onChange(event: React.ChangeEvent<HTMLInputElement>) {
      dispatch(
        Book.setBookDataValueAction(props.keyPath, event.currentTarget.value)
      );
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookBuilderInput);
