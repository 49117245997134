import { every } from 'lodash';

import {
  stringIsPresent,
  stringsArePresent,
  stringsAreEmpty,
  valueIsPresent,
  noValidation
} from 'utils/validations';

import { BookData, BookStyle } from 'types';

// Book Data Validations

/**
 * An object structured like the BookData, but populated by functions. Each
 * function accepts a value and returns `true` if it's valid.
 */
const bookDataValidations: { [field: string]: (value: unknown) => boolean } = {
  bookStyle(value: BookStyle) {
    return value === 'neutral' || value === 'girl' || value === 'boy';
  },
  fullName: stringIsPresent,
  shortName: stringIsPresent,
  authorPronoun: stringIsPresent,
  birthday: valueIsPresent,
  nameOfParents: stringIsPresent,
  birthLocationName: stringIsPresent,
  birthPlace: stringIsPresent,
  birthWeightLb: valueIsPresent,
  birthWeightOz: valueIsPresent,
  birthHeight: valueIsPresent,
  deliveryDoctorName: noValidation,
  firstAddressStreet: stringIsPresent,
  firstAddressLocality: stringIsPresent,
  firstAddressRegion: stringIsPresent,
  firstAddressPostalCode(postalCode: string) {
    return postalCode != null && postalCode.length >= 5;
  },
  nameExplanation(nameExplanation: string) {
    return stringIsPresent(nameExplanation) && nameExplanation.length <= 700;
  },
  familyTreeMembers(value: Array<{ name: string; relationship: string }>) {
    return every(value, (member, index) => {
      if (index < 2) {
        return stringsArePresent([member.name, member.relationship]);
      } else {
        return (
          stringsArePresent([member.name, member.relationship]) ||
          stringsAreEmpty([member.name, member.relationship])
        );
      }
    });
  },
  peoplePresentAtBirth(value: Array<{ name: string }>) {
    return every(value, (member, index) => {
      return index === 0 ? stringIsPresent(member.name) : noValidation();
    });
  },
  birthDayWeather: stringIsPresent,
  personalNote: stringIsPresent,
  nickname1: stringIsPresent,
  nickname2: noValidation,
  nickname3: noValidation
};

// Functions to Validate Book Data

/**
 * Validate a set of fields in the book data. Returns true if all key paths are
 * valid.
 */
export function validateFields(
  bookData: BookData,
  keys: Array<string>
): boolean {
  return keys.every(function (key) {
    return validateField(bookData, key);
  });
}

/**
 * Validates a single field in the book data.
 */
function validateField(bookData: BookData, key: string): boolean {
  const validation = bookDataValidations[key];
  if (validation == null) {
    throw new Error(`No validation found for ${key} for BookData`);
  }
  return validation(bookData[key]);
}
