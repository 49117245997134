import 'styles/application.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import ErrorBoundary from 'components/error-boundary';
import StripeElements from 'components/shared/StripeElements';
import Application from 'components/Application';
import store from '../bundles/application/store';

const container = document.getElementById('container');

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <StripeElements>
        <Application />
      </StripeElements>
    </Provider>
  </ErrorBoundary>,
  container
);
