import { Operation } from 'utils/operations';

import deserializeBookData from '../utils/deserialize-book-data';
import { BookState } from '../index';

type Action = {
  type: 'BOOK/SET_BOOK_DATA';
  payload: BookState['bookData'];
};

class SetBookData extends Operation {
  static actionType = 'BOOK/SET_BOOK_DATA';

  actionCreator(bookData: BookState['bookData']): Action {
    return {
      type: 'BOOK/SET_BOOK_DATA',
      payload: bookData
    };
  }

  reducer(state: BookState, action: Action): BookState {
    return {
      ...state,
      bookData: deserializeBookData(action.payload)
    };
  }
}

export default new SetBookData();
