import React from 'react';
import createPlugin from 'bugsnag-react';

let ErrorBoundary: React.ComponentType;

if (window.bugsnagClient != null) {
  ErrorBoundary = window.bugsnagClient.use(createPlugin(React));
} else {
  ErrorBoundary = ({ children }) => <>{children}</>;
}

export default ErrorBoundary;
