import { Operation } from 'utils/operations';
import { State } from '../index';

class ToggleAddressesAreTheSame extends Operation {
  static actionType = 'CHECKOUT/TOGGLE_ADDRESSES_ARE_THE_SAME';

  reducer(state: State): State {
    return {
      ...state,
      addressesAreTheSame: !state.addressesAreTheSame
    };
  }
}

export default new ToggleAddressesAreTheSame();
