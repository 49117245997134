import { Operation } from 'utils/operations';

import { BookState } from '../index';

type Action = {
  type: 'BOOK/TOGGLE_INFORMATION_VERIFIED';
};

class ToggleInformationVerified extends Operation {
  static actionType = 'BOOK/TOGGLE_INFORMATION_VERIFIED';

  reducer(state: BookState, action: Action): BookState {
    return {
      ...state,
      informationVerified: !state.informationVerified
    };
  }
}

export default new ToggleInformationVerified();
