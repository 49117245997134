import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';

import Book from 'modules/book';
import { State } from 'bundles/application/store';

import BookBuilderParagraph from 'components/book-builder/paragraph';
import BookBuilderField from 'components/book-builder/field';
import BookBuilderSelect from 'components/book-builder/select';
import DateSelect from 'components/book-builder/date-select';
import TimeSelect from 'components/book-builder/time-select';

interface Props {
  name: string;
  birthday: Date;
}

export class BirthDay extends React.PureComponent<Props> {
  static fieldKeys(): Array<string> {
    return ['birthday', 'birthDayWeather'];
  }

  render() {
    const monthString = this.props.birthday.toLocaleString('en-us', {
      month: 'long',
      timeZone: 'UTC'
    });

    return (
      <div className="BookBuilderBirthDay">
        <BookBuilderParagraph>
          <span className="non-input-text">{this.props.name} was born on</span>
          <DateSelect keyPath="birthday" />
        </BookBuilderParagraph>
        <BookBuilderParagraph>
          at <TimeSelect keyPath="birthday" />.
        </BookBuilderParagraph>
        <BookBuilderParagraph>
          <span className="non-input-text">
            The day {this.props.name} was born was a
          </span>
          <BookBuilderField label="Weather Description">
            <BookBuilderSelect
              keyPath="birthDayWeather"
              testId="birthWeather"
              className="BookBuilderBirthDay-weather-select"
            >
              <option />
              <option value="clear">Clear</option>
              <option value="cloudy">Cloudy</option>
              <option value="rain">Rainy</option>
              <option value="snow">Snowy</option>
            </BookBuilderSelect>
          </BookBuilderField>
          <span className="non-input-text">{monthString} day.</span>
        </BookBuilderParagraph>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName,
    birthday: bookData.birthday
  };
}

export default connect(mapStateToProps)(BirthDay);
