/**
 * Utility functions for dealing with persons' names.
 */

/**
 * Generate the possesive form of a name.
 */
export function namePossesive(name: string): string {
  if (name.length === 0) {
    return '';
  }
  if (name.toLowerCase().slice(-1) === 's') {
    return `${name}’`;
  } else {
    return `${name}’s`;
  }
}
