export const toFloat = (value: unknown): number => {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    return parseFloat(value);
  }

  return NaN;
};

export const toInt = (value: unknown): number => {
  if (typeof value === 'number') {
    return Math.floor(value);
  }

  if (typeof value === 'string') {
    return parseInt(value);
  }

  return NaN;
};
