import { call, select } from 'redux-saga/effects';

import { AsyncOperation } from 'utils/operations';
import request from 'utils/request';

class UpdateDiagnostics extends AsyncOperation {
  static actionType = 'CHECKOUT/UPDATE_DIAGNOSTICS';

  *saga(): Generator<any, any, any> {
    const clientUserAgent = navigator.userAgent;
    const clientState = yield select(s => s);

    yield call(
      (clientUserAgent, clientState) => {
        request.put('/api/current-order/diagnostics', {
          order: {
            clientUserAgent,
            clientState: JSON.stringify(clientState)
          }
        });
      },
      clientUserAgent,
      clientState
    );
  }
}

export default new UpdateDiagnostics();
