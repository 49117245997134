import { every } from 'lodash';

// Utility functions intended to be used in validating the state of objects

/// Test string is not null and includes some non whitespace charaters.
export function stringIsPresent(string: string | null | undefined): boolean {
  return string != null && !/^\s*$/.test(string);
}

/// Test all strings are not null and include some non whitespace charaters.
export function stringsArePresent(
  strings: Array<string | null | undefined>
): boolean {
  return every(strings, stringIsPresent);
}

/// Test all strings are empty or just whitespace.
export function stringsAreEmpty(
  strings: Array<string | null | undefined>
): boolean {
  return every(strings, function (string: string | null | undefined) {
    return !stringIsPresent(string);
  });
}

// Test the value is not null or undefined.
export function valueIsPresent(value: unknown): boolean {
  return value != null;
}

// Returns true. Just a convenience function to be descriptive.
export function noValidation(): boolean {
  return true;
}
