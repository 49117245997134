import { Operation } from 'utils/operations';
import { State } from '../index';

class ToggleSubscribeToEmailList extends Operation {
  static actionType = 'CHECKOUT/TOGGLE_SUBSCRIBE_TO_EMAIL_LIST';

  reducer(state: State): State {
    return {
      ...state,
      subscribeToEmailList: !state.subscribeToEmailList
    };
  }
}

export default new ToggleSubscribeToEmailList();
