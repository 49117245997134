import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';

import Book from 'modules/book';
import { State } from 'bundles/application/store';

import { AuthorPronoun } from 'utils/pronoun';

import BookBuilderParagraph from 'components/book-builder/paragraph';
import BookBuilderField from 'components/book-builder/field';
import BookBuilderInput from 'components/book-builder/input';

type Props = {
  name: string;
  visitors: Array<{ name: string }>;
  authorPronoun: 'singular' | 'plural' | null;
};

export class FirstVisitors extends React.PureComponent<Props> {
  node: null | HTMLElement;

  static fieldKeys(): Array<string> {
    return ['peoplePresentAtBirth'];
  }

  render() {
    return (
      <div
        className="BookBuilderFirstVisitors"
        ref={node => {
          this.node = node;
        }}
      >
        <BookBuilderParagraph>
          <span className="non-input-text">
            Besides {new AuthorPronoun(this.props.authorPronoun).objective()},
            the first people to meet {this.props.name} were
          </span>
        </BookBuilderParagraph>

        <BookBuilderParagraph>
          <BookBuilderField label="Name" className="comma-after">
            <BookBuilderInput
              autoFocus
              keyPath="peoplePresentAtBirth.0.name"
              placeholder="Grandpa Jack"
              testId="peoplePresentAtBirth-0"
            />
          </BookBuilderField>
          <BookBuilderField label="Name (Optional)" className="comma-after">
            <BookBuilderInput
              keyPath="peoplePresentAtBirth.1.name"
              placeholder="The Johnsons"
              testId="peoplePresentAtBirth-1"
            />
          </BookBuilderField>
          <BookBuilderField label="Name (Optional)" className="comma-after">
            <BookBuilderInput
              keyPath="peoplePresentAtBirth.2.name"
              testId="peoplePresentAtBirth-2"
            />
          </BookBuilderField>
        </BookBuilderParagraph>

        <BookBuilderParagraph>
          <BookBuilderField label="Name (Optional)" className="comma-after">
            <BookBuilderInput
              keyPath="peoplePresentAtBirth.3.name"
              testId="peoplePresentAtBirth-3"
            />
          </BookBuilderField>
          <BookBuilderField label="Name (Optional)" className="comma-after">
            <BookBuilderInput
              keyPath="peoplePresentAtBirth.4.name"
              testId="peoplePresentAtBirth-4"
            />
          </BookBuilderField>
          <BookBuilderField label="Name (Optional)" className="comma-after">
            <BookBuilderInput
              keyPath="peoplePresentAtBirth.5.name"
              testId="peoplePresentAtBirth-5"
            />
          </BookBuilderField>
        </BookBuilderParagraph>

        <BookBuilderParagraph>
          <BookBuilderField label="Name (Optional)" className="comma-after">
            <BookBuilderInput
              keyPath="peoplePresentAtBirth.6.name"
              testId="peoplePresentAtBirth-6"
            />
          </BookBuilderField>
          <span>and</span>
          <BookBuilderField label="Name (Optional)" className="period-after">
            <BookBuilderInput
              keyPath="peoplePresentAtBirth.7.name"
              testId="peoplePresentAtBirth-7"
            />
          </BookBuilderField>
        </BookBuilderParagraph>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName,
    visitors: bookData.peoplePresentAtBirth,
    authorPronoun: bookData.authorPronoun
  };
}

export default connect(mapStateToProps, null)(FirstVisitors);
