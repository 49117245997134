import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';

const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');

let csrfToken = csrfTokenElement?.getAttribute('content');

const httpLink = new HttpLink({
  uri: '/graphql',
  fetch: async (uri, options = {}) => {
    if (options.headers == null) {
      options.headers = {};
    }

    options.headers['X-CSRF-Token'] = csrfToken;

    const response = await fetch(uri, options);

    // Reload to display the maintenance page if the response status is 503
    if (response.status === 503) {
      location.reload();

      throw new Error('Service unavailable');
    }

    const responseCsrfToken = response.headers.get('X-CSRF-Token');

    if (responseCsrfToken != null) {
      csrfToken = responseCsrfToken;
    }

    return response;
  }
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

export default client;
