import { put, call, takeEvery } from 'redux-saga/effects';
import { bindAll } from 'lodash';
import { notify } from 'utils/bugsnag';

export interface OperationType<State, Payload = null> {
  actionCreator(): { type: string; payload: Payload };
  reducer(state: State, action: { type: string; payload?: Payload }): State;
}

export class Operation {
  static actionType: string = 'OPERATION';

  constructor() {
    bindAll(this, ['actionCreator', 'reducer']);
  }

  actionCreator(...args: Array<any>): any {
    return {
      type: (this.constructor as typeof Operation).actionType
    };
  }

  reducer(state: any, action: any): any {
    return state;
  }
}

export class AsyncOperation extends Operation {
  constructor() {
    super();

    bindAll(this, ['saga']);
  }

  request(action: any): Promise<any> {
    return Promise.resolve({});
  }

  *saga(action: any): Generator<any, any, any> {
    if (action.status === null) {
      yield this.putPending();

      try {
        const result = yield call(this.request, action);

        yield this.putSuccess(result);
      } catch (error) {
        yield this.putError(error);
      }
    }
  }

  putPending(payload: any = null) {
    return put({
      type: (this.constructor as typeof AsyncOperation).actionType,
      status: 'pending',
      payload
    });
  }

  putSuccess(payload: any = null) {
    return put({
      type: (this.constructor as typeof AsyncOperation).actionType,
      status: 'success',
      payload
    });
  }

  putError(payload: any = null) {
    if (process.env.NODE_ENV === 'development' && payload instanceof Error) {
      console.error(payload);
    }

    if (payload instanceof Error) {
      notify(payload);
    }

    return put({
      type: (this.constructor as typeof AsyncOperation).actionType,
      status: 'error',
      payload
    });
  }
}

export class OperationModule {
  static initialState: any;
  static operations: Array<Operation> = [];

  operations: {
    [key: string]: Operation | AsyncOperation;
  };

  constructor() {
    this.operations = {};

    (this.constructor as typeof OperationModule).operations.forEach(
      operation => {
        this.operations[
          (operation.constructor as typeof Operation).actionType
        ] = operation;
      }
    );

    bindAll(this, ['reducer', 'saga']);
  }

  reducer(
    state: Record<string, unknown> | null | undefined,
    action: { type: string } | null | undefined
  ) {
    if (state === undefined) {
      return (this.constructor as typeof OperationModule).initialState;
    }

    if (action != null && this.operations[action.type] != null) {
      return this.operations[action.type].reducer(state, action);
    }

    return state;
  }

  *saga(): Generator<any, any, any> {
    for (const key in this.operations) {
      if (Object.prototype.hasOwnProperty.apply(this.operations, [key])) {
        const operation = this.operations[key];
        if ('saga' in operation) {
          yield takeEvery(
            (operation.constructor as typeof Operation).actionType,
            operation.saga
          );
        }
      }
    }
  }
}
