import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Book from 'modules/book';
import { State, Dispatch } from 'bundles/application/store';

type HTMLSelectElementProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

interface Props extends Omit<Omit<HTMLSelectElementProps, 'onChange'>, 'ref'> {
  value?: string | number;
  keyPath?: string;
  numeric?: boolean;
  testId?: string;
  onChange?(value: string | number | null): void;
}

interface ProvidedProps extends Props {
  onChange(value: string | number | null): void;
}

/**
 * Select field tailored ot the book builder. Children are expected to be a set of `option`s.
 *
 * Import the default (connected to store) component and provide a keypath to automatically bind
 * to the book data in the store.
 *
 * Import the component explicity to provide another `value` and `onChange` handler.
 */
export class BookBuilderSelect extends React.PureComponent<ProvidedProps> {
  render() {
    const {
      value,
      numeric,
      testId,
      className,
      keyPath,
      onChange,
      ...selectProps
    } = this.props;

    return (
      <select
        tabIndex={1}
        {...selectProps}
        value={value == null ? '' : value.toString()}
        className={classNames('BookBuilderSelect', className)}
        data-test-id={testId}
        onChange={event => {
          let value: string | number | null = event.currentTarget.value;

          if (numeric === true) {
            value = parseFloat(value);

            if (isNaN(value)) {
              value = null;
            }
          }

          onChange(value);
        }}
      >
        {this.props.children}
      </select>
    );
  }
}

function mapStateToProps(state: State, props: Props) {
  if (!props.keyPath) {
    return null;
  }

  return {
    value: Book.getBookDataValue(state, props.keyPath)
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props) {
  return {
    onChange(value: string | number | null) {
      if (props.keyPath != null) {
        dispatch(Book.setBookDataValueAction(props.keyPath, value));
      }

      if (props.onChange != null) {
        props.onChange(value);
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookBuilderSelect);
