import { indexOf, findLastIndex } from 'lodash';

import TitlePage from './title-page';
import BookStyle from './book-style';
import BirthDay from './birth-day';
import BirthDetails from './birth-details';
import FamilyTree from './family-tree';
import FirstHome from './first-home';
import FirstVisitors from './first-visitors';
import NameExplanation from './name-explanation';
import WelcomeMessage from './welcome-message';

export const BookBuilderPages = [
  TitlePage,
  BookStyle,
  BirthDay,
  BirthDetails,
  FamilyTree,
  FirstVisitors,
  FirstHome,
  NameExplanation,
  WelcomeMessage
];

/**
 * Determines the lastet page a user can edit a given field on in the book builder. Used to
 * generate the path for the edit links on the review step of the book builder.
 */
export function lastIndexOfPageWithField(field: string): number {
  return findLastIndex(
    BookBuilderPages,
    page => indexOf(page.fieldKeys(), field) > -1
  );
}
