declare global {
  interface Window {
    bugsnagClient: {
      use(plugin: any): React.ComponentType;
      leaveBreadcrumb(name: string, metaData?: Record<string, unknown>): void;
      notify(error: Error, options?: Record<string, unknown>): void;
    };
  }
}

export function leaveBreadcrumb(
  name: string,
  metaData?: Record<string, unknown>
) {
  if (window.bugsnagClient != null) {
    window.bugsnagClient.leaveBreadcrumb(name, metaData);
  }
}

export function notify(error: Error, options?: Record<string, unknown>) {
  if (window.bugsnagClient != null) {
    window.bugsnagClient.notify(error, options);
  }
}
