import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  StoreEnhancer
} from 'redux';

import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import Book, { BookState } from 'modules/book';
import Checkout, { State as CheckoutState } from 'modules/checkout';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?(
      enhancer: StoreEnhancer
    ): StoreEnhancer;
  }
}

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  book: Book.reducer,
  checkout: Checkout.reducer
});

export interface State {
  book: BookState;
  checkout: CheckoutState;
}

export type Dispatch = any;

function* rootSaga() {
  yield all([Book.saga(), Checkout.saga()]);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
