import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import { range } from 'lodash';

import Book from 'modules/book';
import { State, Dispatch } from 'bundles/application/store';
import { dateMergingComponents, monthNames } from 'utils/dates';

import BookBuilderField from '../field';
import { BookBuilderSelect } from '../select';

interface Props {
  keyPath: string;
}

interface ProvidedProps extends Props {
  value: Date;
  onChange(date: Date): void;
}

/**
 * Renders three dropdowns to select a date. Structured specifically for the book builder. Connected
 * to the store in the same manner as `builder fields`.
 *
 * The default export is connected ot the store, but the unwrapped class can be imported directly
 * to handle events another way.
 */
export class BookBuilderDateSelect extends React.PureComponent<ProvidedProps> {
  handleChange(component: 'day' | 'month' | 'year', value: unknown) {
    this.props.onChange(
      dateMergingComponents(this.props.value, {
        [component]: value
      })
    );
  }

  render() {
    const date = this.props.value;

    return (
      <div className="BookBuilderDateSelect">
        <BookBuilderField label="Month" small={true}>
          <BookBuilderSelect
            numeric
            value={date.getUTCMonth()}
            testId="birthMonth"
            onChange={this.handleChange.bind(this, 'month')}
          >
            {monthNames.map((month, index) => {
              return (
                <option value={index} key={index}>
                  {month}
                </option>
              );
            })}
          </BookBuilderSelect>
        </BookBuilderField>

        <BookBuilderField label="Day" small={true}>
          <BookBuilderSelect
            numeric
            value={date.getUTCDate()}
            testId="birthDay"
            onChange={this.handleChange.bind(this, 'day')}
          >
            {range(1, 32).map(day => {
              return (
                <option value={day} key={day}>
                  {day}
                </option>
              );
            })}
          </BookBuilderSelect>
        </BookBuilderField>
        <span>,</span>
        <BookBuilderField label="Year" small={true}>
          <BookBuilderSelect
            numeric
            value={date.getUTCFullYear()}
            testId="birthYear"
            onChange={this.handleChange.bind(this, 'year')}
          >
            {range(2007, 2025).map(year => {
              return (
                <option value={year} key={year}>
                  {year}
                </option>
              );
            })}
          </BookBuilderSelect>
        </BookBuilderField>
      </div>
    );
  }
}

function mapStateToProps(state: State, props: Props) {
  return {
    value: Book.getBookDataValue(state, props.keyPath)
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props) {
  return {
    onChange(value: Date) {
      dispatch(Book.setBookDataValueAction(props.keyPath, value));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookBuilderDateSelect);
