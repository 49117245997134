import './styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Book from 'modules/book';
import { State, Dispatch } from 'bundles/application/store';

import { BookStyle as BookStyleKey } from 'types';

import neutralUrl from 'assets/images/book-builder/book-style-neutral.png';
import girlUrl from 'assets/images/book-builder/book-style-girl.png';
import boyUrl from 'assets/images/book-builder/book-style-boy.png';

import BookBuilderParagraph from 'components/book-builder/paragraph';

const styles = [
  { key: 'neutral', imageUrl: neutralUrl },
  { key: 'girl', imageUrl: girlUrl },
  { key: 'boy', imageUrl: boyUrl }
];

type Props = {
  name: string;
  bookStyle: BookStyleKey;
  onChange(style: BookStyleKey): void;
};

export class BookStyle extends React.PureComponent<Props> {
  static fieldKeys(): Array<string> {
    return ['bookStyle'];
  }

  render() {
    return (
      <div className="BookBuilderPagesBookStyle">
        <div>
          <h3>Which style will {this.props.name} like best?</h3>
        </div>

        <BookBuilderParagraph>
          {styles.map(style => {
            return (
              <a
                className={classNames(
                  'BookBuilderPagesBookStyle-style',
                  `BookBuilderPagesBookStyle-style-${style.key}`,
                  {
                    selected: style.key === this.props.bookStyle
                  }
                )}
                key={style.key}
                data-test-id={`${style.key}Style`}
                onClick={this.props.onChange.bind(null, style.key)}
              >
                <img src={style.imageUrl} />
              </a>
            );
          })}
        </BookBuilderParagraph>
        <div className="BookBuilderPagesBookStyle-warning">
          Your selection will result in slightly different accent colors on a
          select number of pages in your book.
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const bookData = Book.getBookData(state);

  return {
    name: bookData.shortName,
    bookStyle: bookData.bookStyle
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onChange(value: BookStyleKey) {
      dispatch(Book.setBookDataValueAction('bookStyle', value));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookStyle);
