import { put, call, select } from 'redux-saga/effects';
import { get } from 'lodash';

import { AsyncOperation } from 'utils/operations';
import request from 'utils/request';

import Checkout from '../index';
import Book from 'modules/book';

import updateDiagnostics from './update-diagnostics';
import { notify } from 'utils/bugsnag';

import { Address, BookData } from 'types';
import { State } from '../index';

type Action = {
  type: 'CHECKOUT/UPDATE';
  status: null | 'pending' | 'success' | 'error';
  payload: {
    createToken?(): Promise<{ id: string }>;
    completeLater?: boolean;
  };
};

type RequestOptions = {
  quantity: number;
  email: string;
  billAddress: Address;
  shipAddress: Address;
  promotionCode?: string;
  giftCertificateVariant?: 'digital' | 'physical';
  bookData?: BookData;
  includeMatchingPoster: boolean;
  completeLater: boolean;
};

class Update extends AsyncOperation {
  static actionType = 'CHECKOUT/UPDATE';

  actionCreator(payload: Action['payload'] = {}): Action {
    return {
      type: 'CHECKOUT/UPDATE',
      status: null,
      payload
    };
  }

  reducer(state: State, action: Action): State {
    switch (action.status) {
      case 'pending': {
        state = {
          ...state,
          updateStatus: 'pending',
          errors: []
        };

        if (action.payload && action.payload.completeLater === true) {
          state = {
            ...state,
            completeLater: true
          };
        }

        return state;
      }

      case 'success': {
        // Clear the promotion code input if the pormotion has been applied
        // successfully.
        const promotionCode =
          get(action, 'payload.order.promotionCode', null) ===
          state.promotionCode.toUpperCase().trim()
            ? ''
            : state.promotionCode;

        return {
          ...state,
          updateStatus: 'success',
          promotionCode,
          taxTotal: get(action, 'payload.order.taxTotal', null),
          total: get(action, 'payload.order.total', null),
          adjustments: get(action, 'payload.order.adjustments', [])
        };
      }

      case 'error': {
        return {
          ...state,
          updateStatus: 'error',
          errors: get(action, 'payload.response.data.errors', [])
        };
      }
    }

    return state;
  }

  request(options: RequestOptions) {
    const { includeMatchingPoster } = options;

    const order = {
      email: options.email,
      book: {},
      giftCertificate: {},
      billAddress: options.billAddress,
      shipAddress: options.shipAddress,
      payment: {},
      promotionCode: null,
      completeLater: options.completeLater
    };

    let promotionCode = null;

    if (options.promotionCode != null) {
      promotionCode = options.promotionCode;
    }

    if (options.giftCertificateVariant != null) {
      order.giftCertificate = {
        variant: options.giftCertificateVariant
      };
    } else {
      order.book = {
        quantity: options.quantity,
        customData: options.bookData
      };
    }

    return request
      .put('/api/current-order', {
        order,
        includeMatchingPoster,
        promotionCode
      })
      .then(({ data }) => data);
  }

  *saga(action: Action): Generator<any, any, any> {
    if (action.status === null) {
      yield this.putPending();

      try {
        yield this.checkoutRequest(action);
      } catch (error) {
        notify(error);

        // Stripe.js request error
        yield this.putError(error);
      }

      yield put(updateDiagnostics.actionCreator());
    }
  }

  // TODO: Some of this logic can be shared with "checkoutRequest" in the
  // complete operation.
  *checkoutRequest(action: Action): Generator<any, any, any> {
    const checkoutState: State = yield select(Checkout.getAll);
    const bookData: BookData = yield select(Book.getBookData);

    let billAddress, shipAddress;

    const paymentRequired =
      checkoutState.total && parseFloat(checkoutState.total) > 0;

    if (checkoutState.giftCertificateVariant == null) {
      // If this is a book checkout, the shipping address is first.

      billAddress = checkoutState.addressesAreTheSame
        ? checkoutState.shipAddress
        : checkoutState.billAddress;

      shipAddress = checkoutState.shipAddress;
    } else {
      // If this is a gift certificate checkout, the billing address is first.

      billAddress = checkoutState.billAddress;

      // If the addresses are the same and payment is required (i.e. we have a
      // bill address), then use the bill address for the ship address.
      shipAddress =
        checkoutState.addressesAreTheSame && paymentRequired
          ? checkoutState.billAddress
          : checkoutState.shipAddress;
    }

    let quantity = checkoutState.quantity;

    if (quantity < 1) {
      quantity = 1;
    }

    const options: RequestOptions = {
      email: checkoutState.email,
      quantity,
      billAddress,
      shipAddress,
      includeMatchingPoster: checkoutState.includeMatchingPoster,
      completeLater: action.payload.completeLater || false
    };

    if (checkoutState.promotionCode.length > 0) {
      options.promotionCode = checkoutState.promotionCode;
    }

    if (checkoutState.giftCertificateVariant != null) {
      options.giftCertificateVariant = checkoutState.giftCertificateVariant;
    } else {
      options.bookData = bookData;
    }

    try {
      const checkoutResponse = yield call(this.request, options);

      yield this.putSuccess(checkoutResponse);
    } catch (error) {
      notify(error);

      // Checkout request error
      yield this.putError(error);
    }
  }
}

export default new Update();
