import { get } from 'lodash';
import { toFloat } from './conversions';

declare global {
  interface Window {
    gtag?(arg0: string, arg1: string, arg2?: Record<string, unknown>): void;

    fbq?(arg0: string, arg1: string, arg2: Record<string, unknown>): void;
  }
}

import { notify } from 'utils/bugsnag';

export default function trackConversion(response: Record<string, unknown>) {
  try {
    const giftCertificateVariant = get(
      response,
      'order.giftCertificate.variant',
      null
    );

    let eventID;

    if (giftCertificateVariant === 'digital') {
      eventID = adWordsEventIDs.GIFT_CARD_SALE;
    } else if (giftCertificateVariant === 'physical') {
      eventID = adWordsEventIDs.GIFT_BOX_SALE;
    } else {
      eventID = adWordsEventIDs.BOOK_SALE;
    }

    const value = toFloat(get(response, 'order.total', 0));

    // Track sale using Google AdWords
    if (window.gtag != null) {
      window.gtag('event', 'conversion', {
        send_to: eventID,
        value,
        currency: 'USD',
        transaction_id: get(response, 'order.number')
      });
    }

    // Track sale using Facebook Pixel
    if (window.fbq != null) {
      window.fbq('track', 'Purchase', {
        value,
        currency: 'USD'
      });
    }
  } catch (error) {
    notify(error);
  }
}

const adWordsEventIDs = {
  BOOK_SALE: 'AW-828019750/58AgCOS2q3oQpqjqigM',
  GIFT_CARD_SALE: 'AW-828019750/Vh0UCI2monoQpqjqigM',
  GIFT_BOX_SALE: 'AW-828019750/lubWCM2tonoQpqjqigM'
};
